<script setup lang="ts">
const props = defineProps<{
  content?: string
  class?: string
}>()

const content = computed(() => contentReplacer(props?.content))
</script>

<template>
  <div v-if="props?.content" :class="props?.class" v-html="content" />
</template>
