export default function (content: any) {
  if (!content)
    return null

  const matches = content.match(/(?<=\[).+?(?=\])/g)
  // const matches1 = content.match(/\[.+?\]/g)

  if (!matches || matches?.length === 0)
    return content

  let result = _clone(content)

  matches.forEach((item: any) => {
    const e = _split(item, ';')
    if (e.length) {
      let buttonLabel = ''
      let buttonAnchor = ''
      let buttonLink = ''
      let buttonColor = 'orange'
      let buttonTarget = '_self'

      e.forEach((i: any) => {
        let [first, ...rest] = i.split(':')
        rest = rest.join(':')

        if (first === 'button')
          buttonLabel = rest

        if (first === 'anchor')
          buttonAnchor = rest

        if (first === 'link')
          buttonLink = rest

        if (first === 'color')
          buttonColor = rest

        if (first === 'target')
          buttonTarget = rest
      })

      if (buttonLabel) {
        result = _replace(
          result,
          `[${item}]`,
          `<a
            href="${buttonAnchor ? `#${buttonAnchor}` : buttonLink}"
            target="${buttonAnchor ? '_self' : buttonTarget}"
            title="${buttonLabel}"
            class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-${buttonColor}-500 bg-none px-12 py-4 text-center text-xl font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-${buttonColor}-400"
          >${buttonLabel}</a>`,
        )
      }
    }
  })

  return result
}
